import { shallowMount } from '@vue/test-utils';
import Game from '@/pages/animals_in_order_blind/game';

jest.mock('vue-meta');
jest.mock('vue-i18n', () => ({
  useI18n: jest.fn(() => ({
    t: () => {},
  })),
}));


describe('AnimalsInOrderBlindGame.vue', () => {
  it('renders child components', () => {
    const wrapper = shallowMount(Game);

    expect(wrapper.findComponent({ name: 'GamePlay' }).exists()).toBe(true);
  });

  it('is blind mode', () => {
    const wrapper = shallowMount(Game);

    expect(wrapper.findComponent({ name: 'GamePlay' }).vm.isBlindMode).toBe(true);
  });
});
